<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆维修</el-breadcrumb-item>
      <el-breadcrumb-item>维修结算</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 头部 -->
      <div class="top">
        <div class="tops">
          <span>日期：</span>
          <!-- <el-date-picker v-model="month" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月"></el-date-picker> -->
          <el-date-picker
            @change="gettime"
            v-model="timeFrom"
            :picker-options="pickerOptions"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>

        <div class="tops">
          <span>维修单位：</span>
          <el-select v-model="repair_man" placeholder="未选择" clearable @clear="handleEmptyno">
            <el-option v-for="item in tableDatas" :key="item.id" :label="item.maintenance_company" :value="item.id" @click.native="chetype(item.id)"></el-option>
          </el-select>
        </div>
        <el-button class="boder">月结总计 {{ toFixed(month_settlement1_money) }} 元</el-button>
      </div>

      <!-- <div> -->
      <!-- <el-button class="boder">月结总计 {{ month_settlement1_money }} 元</el-button> -->
      <!-- <el-button class="boder">维修总计{{ year_total_money }} 元</el-button> -->
      <!-- </div> -->

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="maintenance_time_at" label="维修日期"></el-table-column>

        <el-table-column prop="auto_number" label="车牌号"></el-table-column>

        <el-table-column label="车辆类型" width="180">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.get_use_type ? scope.row.get_use_type.tname : '管理车辆' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="维修公里数(KM)">
          <template slot-scope="scope">
            <div id="test">{{ toFixed(scope.row.kilometre) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="维修总计(元)" width="180">
          <template slot-scope="scope">
            <div id="test">{{ toFixed(scope.row.money / 100) }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="auto_driver" label="司机"></el-table-column>

        <!-- <el-table-column label="维修单位" width="180">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.get_maintenance_company ? scope.row.get_maintenance_company.maintenance_company : '无' }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="maintenance_company" label="维修单位" width="180"></el-table-column>

        <el-table-column prop="sett_method_cn" label="结算方式"></el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Getlist, Getweilist } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      timeFrom: [],
      start_time: '',
      end_time: '',
      month_settlement1_money: 0, // 月结费用统计
      month: '', // 日期
      repair_man: '', // 维修单位
      tableDatas: [], // 维修单位数据
      month_total_money: '', // 月度
      year_total_money: '', // 年度
      tableData: [], // 列表
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Getlists()
    this.Getweilistya()
  },
  methods: {
    toFixed(val) {
      return parseFloat(val).toLocaleString('en', {
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2,
      })
    },
    toFixeds(val) {
      return parseFloat(val).toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    // 时间
    gettime(month) {
      console.log(month)
      this.start_time = month[0]
      console.log(this.start_time)
      this.end_time = month[1]
      console.log(this.end_time)
      this.Getweilistya()
    },

    // 维修单位选中
    chetype(e) {
      // console.log(e)
      this.Getweilistya()
    },
    handleEmptyno() {
      this.repair_man = ''
      this.Getweilistya()
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getweilistya()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getweilistya()
    },

    //////// 请求区///////////

    // 拉取维修单位列表
    async Getlists() {
      const { data } = await Getlist({ status: 1, isAll: true })
      // console.log(data)
      this.tableDatas = data.data.list
    },

    // 拉取维修信息列表
    async Getweilistya() {
      const { data } = await Getweilist({ ...this.pageData, start_time: this.start_time, end_time: this.end_time, repair_man: this.repair_man })
      // console.log(data)
      this.tableData = data.data.list
      this.month_total_money = data.data.month_total_money / 100
      this.year_total_money = data.data.year_total_money / 100
      this.month_settlement1_money = data.data.month_settlement1_money / 100
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    }
  }
}
</script>

<style scoped>
.top {
  width: 1000px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
</style>